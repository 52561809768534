module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-flexsearch/gatsby-browser.js'),
      options: {"plugins":[],"type":"MarkdownRemark","fields":[{"name":"id","indexed":false,"resolver":"id","store":true},{"name":"templateKey","indexed":false,"resolver":"frontmatter.templateKey","store":true},{"name":"slug","indexed":false,"resolver":"fields.slug","store":true},{"name":"locale","indexed":false,"resolver":"fields.locale","store":true},{"name":"title","indexed":true,"resolver":"frontmatter.title","attributes":{"encode":"extra","tokenize":"full","threshold":1,"resolution":3},"store":true},{"name":"tags","indexed":true,"resolver":"frontmatter.tags","attributes":{"encode":"extra","tokenize":"full","threshold":1,"resolution":3},"store":true},{"name":"overview","indexed":true,"resolver":"frontmatter.overview","attributes":{"encode":"icase","tokenize":"forward","threshold":2,"depth":3},"store":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js","publicPath":"cms"},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"disinformationindex.org","excludePaths":["/cms/*"]},
    }]
